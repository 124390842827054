<template>
  <admin>
    <metatag title="Inventories"></metatag>
    <page-header>
      <template v-slot:action>
        <can :code="code">
          <v-menu bottom offset-y class="pointer">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :block="$vuetify.breakpoint.smAndDown" large color="primary" exact v-bind="attrs" v-on="on"
                class="mx-5 mx-md-2 mt-2 mt-md-0 white--text">
                <v-icon small left>mdi-plus-circle</v-icon>
                Add new
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'inventories.create' }">
                <v-list-item-title>
                  <v-icon small left>mdi-plus-circle</v-icon>
                  <span class="text-center px-8 py-1">Individual</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'inventories.upload' }">
                <v-list-item-title>
                  <v-icon small left>mdi-cloud-upload</v-icon>
                  <span class="text-center px-8 py-1">Upload via excel</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </can>
      </template>
    </page-header>
    <v-card>
      <toolbar-menu ref="toolbar" :items.sync="tabletoolbar" @update:search="setSearch"></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table :headers="getTableHeaders()"
          :items="items" :options.sync="options" :server-items-length="total" :loading="loading" :items-per-page="5"
          color="primary" item-key="id" class="text-no-wrap">

          <template v-slot:item.id="{ index, item }">
            <div class="text-no-wrap">
              <!-- Preview -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editItem(item)" text color="primary" icon v-on="on">
                    {{ index + 1 }}
                  </v-btn>
                </template>
                <span v-text="'Preview'"></span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.dealer_name="{ item }">
            <span>{{ item.dealer_name }}</span>
          </template>
          <template v-slot:item.vehicle="{ item }">
            <span>{{ item.model_name }}</span><br />
            <span>{{ item.variant_name }}</span><br />
            <span>{{ item.color_name }}</span>
          </template>
          <template v-slot:item.vehicle_id="{ item }">
            <span>{{ item.variant_code }}</span>
          </template>
          <template v-slot:item.amount="{ item }">
            <span>{{ item.price ? formatPrice(item.price.price) : formatPrice(item.price) }}</span>
          </template>
          <template v-slot:item.date_updated="{ item }">
            <span>{{ formatDate(item.updated_at) }}</span>
          </template>
          <template v-slot:item.vehicle_model="{ item }">
            <span>{{ item.model_name }}</span>
          </template>
          <template v-slot:item.color_description="{ item }">
            <span>{{ item.color_name }}</span>
          </template>
          <template v-slot:item.vehicle_desc="{ item }">
            <span>{{ item.variant_name }}</span>
          </template>
          <!-- Action buttons -->
          <template v-slot:item.actions="{ item }">
            <div class="text-no-wrap">
              <!-- Edit -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon v-on="on" @click="editItem(item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Edit'"></span>
              </v-tooltip>
              <!-- Edit -->
            </div>
          </template>
          <!-- Action buttons -->
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="900px" class="overflow-hidden">
      <v-card class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <h2 title="Edit" class="mb-1">Edit Inventory</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <!-- Background Details -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">{{ "Model" }}</td>
                    <td>
                      {{ selectedItem.model_name }}
                    </td>
                    <td class="font-weight-bold">{{ "Vechicle" }}</td>
                    <td>{{ selectedItem.variant_name }} - {{ selectedItem.variant_code }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Year Model" }}</td>
                    <td>{{ selectedItem.model_year }}</td>
                    <td class="font-weight-bold">{{ "Color" }}</td>
                    <td>{{ selectedItem.color_name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Dealer" }}</td>
                    <td>{{ selectedItem.dealer_code }}</td>
                    <td class="font-weight-bold">{{ "Date Updated" }}</td>
                    <td>{{ selectedItem.updated_at ? formatDate(selectedItem.updated_at) : '-' }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Price" }}</td>
                    <td>{{ selectedItem.price ? formatPrice(selectedItem.price.price) : '0.00' }}</td>
                    <td class="font-weight-bold">{{ "Quantity" }}</td>
                    <td>
                      <v-text-field label="Quantity" class="dt-text-field" outlined
                        prepend-inner-icon="mdi-format-list-numbered-rtl" v-model="selectedItem.quantity"
                        :rules="[(value) => !!value || 'Quantity is required']"></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- Background Details -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="closePreview">
              Cancel
            </v-btn>
            <v-btn large exact color="green darken-1" class="ma-1 white--text px-5" :loading="loadingSave"
              :disabled="loadingSave" @click="validate">
              <v-icon left>mdi-content-save</v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      tabletoolbar: {
        isSearching: false,
        search: null,
        models: [],
      },
      selectedItem: {},
      editDialog: false,
      code: ['admin'],
      loading: true,
      options: {},
      items: [],
      total: 0,
      valid: true,
      loadingSave: false,
      q: "",
      tableData: {
        headers: {
          admin: [
            { text: "#", align: "left", value: "id", class: "text-no-wrap" },
            { text: "Dealer Name", align: "left", value: "dealer_name", class: "text-no-wrap" },
            { text: "Vehicle", align: "center", value: "vehicle", class: "text-no-wrap" },
            { text: "Year Model", align: "center", value: "model_year", class: "text-no-wrap" },
            { text: "Vehicle ID", align: "center", value: "vehicle_id", class: "text-no-wrap" },
            { text: "Inventory", align: "center", value: "quantity", class: "text-no-wrap" },
            { text: "Price", align: "center", value: "amount", class: "text-no-wrap" },
            { text: "Date updated", align: "center", value: "date_updated", class: "text-no-wrap" },
            {
              text: "Actions",
              value: "actions",
              class: "muted--text text-no-wrap",
              align: "center",
              sortable: false,
            },
          ],
          dealer: [
            {
              text: "Vehicle Model",
              align: "center",
              value: "vehicle_model",
              class: "text-no-wrap",
            },
            { text: "Vehicle Desc", align: "center", value: "vehicle_desc", class: "text-no-wrap" },
            { text: "Year Model", align: "center", value: "model_year", class: "text-no-wrap" },
            {
              text: "Color Description",
              align: "center",
              value: "color_description",
              class: "text-no-wrap",
            },
            { text: "Inventory", align: "center", value: "quantity", class: "text-no-wrap" },
          ],
        },
      },
    };
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage, this.q);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "inventories/GET_INVENTORIES",
      userRoles: "auth/GET_ROLES",
    }),
  },
  async mounted() {
    await this.getUserType();
  },
  methods: {
    ...mapActions({
      getInventories: "inventories/list",
      getUserType: "auth/getUserType",
      updateInventory: "inventories/updateInventory",
    }),

    getTableHeaders() {
      // Set user type
      let user_roles = this.userRoles.map(a => a.slug);

      if (user_roles.includes("dealer")) {
       return this.tableData.headers.dealer;
      }

      if (user_roles.includes("admin")) {
       return this.tableData.headers.admin;
      }
    },

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.q = e.target.value;
      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    async getItems(page, itemsPerPage, q = "") {
      let data = {
        page,
        per_page: itemsPerPage,
      };
      if (q) {
        this.q = q;
        data.q = q;
      }
      await this.getInventories(data).then((data) => {
        this.items = this.data.data;
        this.total = this.data.meta.total;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    formatDate(item) {
      return helpers.format_date(item);
    },

    formatPrice(item) {
      return helpers.format_price(item);
    },

    async editItem(item) {
      this.selectedItem = {
        color_code: item.color_code,
        color_name: item.color_name,
        dealer_code: item.dealer_code,
        dealer_name: item.dealer_name,
        variant_code: item.variant_code,
        variant_name: item.variant_name,
        quantity: item.quantity,
        model_name: item.model_name,
        price: item.price,
        updated_at: item.updated_at,
        model_year: item.model_year,
        dealer_id: item.dealer_id
      };
      this.editDialog = true
    },

    closePreview() {
      this.editDialog = false;
      this.selectedItem = {};
    },

    async validate() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.loadingSave = true;
      await this.handleUpdateInventory();
    },

    async handleUpdateInventory() {
      const data = {
        color_code: this.selectedItem.color_code,
        color_name: this.selectedItem.color_name,
        dealer_code: this.selectedItem.dealer_code,
        dealer_name: this.selectedItem.dealer_name,
        variant_code: this.selectedItem.variant_code,
        variant_name: this.selectedItem.variant_name,
        quantity: this.selectedItem.quantity,
        model_name: this.selectedItem.model_name,
        model_year: this.selectedItem.model_year,
        dealer_id: this.selectedItem.dealer_id
      }
      await this.updateInventory(data);
      this.editDialog = false;
      this.selectedItem = {};
      this.loadingSave = false;
      await this.getItems(1, 5, "");
    },
  },
};
</script>

<style></style>
